@import 'styles/groenlinks.css';
@import 'styles/overwrites.css';


/* campaign-spread-person */
.campaign-spread-person {
    --layout-campaign-spread-medium-content: column 1 / span 7;
    --layout-campaign-spread-medium-image: column 1 / span 4;
}

@media(max-width: 529px) {
    .campaign-spread-person.campaign-spread-medium .campaign-spread-medium__image-container {
        margin-bottom: 1em;
    }
}

@media(min-width: 530px) {
    .campaign-spread-person {
        --layout-campaign-spread-medium-content: column 5 / span 8;
        --layout-campaign-spread-medium-image: column 1 / span 4;
    }
}
@media(min-width: 800px) {
    .campaign-spread-person {
        --layout-campaign-spread-medium-content: column 6 / span 10;
        --layout-campaign-spread-medium-image: column 2 / span 4
    }
}
@media(min-width: 1180px) {
    .campaign-spread-person {
        --layout-campaign-spread-medium-content: column 6 / span 12;
        --layout-campaign-spread-medium-image: column 2 / span 4;
    }
}
@media(min-width: 1580px) {
    .campaign-spread-person {
        --layout-campaign-spread-medium-content: column 7 / span 12;
        --layout-campaign-spread-medium-image: column 3 / span 4;
    }
}

@media(min-width: 2240px) {
    .campaign-spread-person {
        --layout-campaign-spread-medium-content: column 7 / span 12;
        --layout-campaign-spread-medium-image: column 3 / span 4;
    }
}

.campaign-spread-person.campaign-spread-medium .campaign-spread-medium__image-container {
    background: none;
}

/* campaign-spread-split */
.campaign-spread-split {
    --layout-campaign-spread-split-left: column 1 / span 7;
    --layout-campaign-spread-split-right: column 1 / span 7;
    position: relative;
    overflow: hidden;
}

.campaign-spread-split .campaign-spread-medium__grid {
    grid-gap: 0;
}
.campaign-spread-split .campaign-spread-split__left {
    padding: 2em 1em;
    grid-column: var(--layout-campaign-spread-split-left);
}
.campaign-spread-split .campaign-spread-split__right {
    padding: 2em 1em;
    grid-column: var(--layout-campaign-spread-split-right);
}

.campaign-spread-split__divider {
    grid-column: column 1 / span 16;
    height: calc(var(--margin) * 1.5);
    background-color: #f7f2f3;
    z-index: 1;
}

@media(min-width: 800px) {
    .campaign-spread-split {
        --layout-campaign-spread-split-left: column 2 / span 8;
        --layout-campaign-spread-split-right: column 10 / span 8;
    }

    .campaign-spread-split__divider {
        bottom: -50%;
        height: unset;
        left: 50%;
        margin-left: -1em;
        position: absolute;
        top: -50%;
        transform: rotate(10deg);
        width: 1em;
    }

    .campaign-spread-split .campaign-spread-split__left,
    .campaign-spread-split .campaign-spread-split__right {
        padding: var(--column-offset);
    }

    .campaign-spread-split .campaign-spread-split__right {
        text-align: right;
    }

    .campaign-spread-split .campaign-spread-split__right ul {
        padding: 0;
    }
    .campaign-spread-split .campaign-spread-split__right li {
        color: #533bff;
        font-weight: bold;
        list-style: none;
        margin: 2em 0 1em;
    }
}

@media(min-width: 1180px) {
    .campaign-spread-split__divider {
        transform: rotate(20deg);
    }
}

@media(min-width: 1580px) {
    .campaign-spread-split {
        --layout-campaign-spread-split-right: column 11 / span 8;
        --layout-campaign-spread-split-left: column 3 / span 8;
    }
}

.campaign-spread__count {
    display: block;
}

.campaign-spread__count-progress::-webkit-progress-value {
    transition: width 2s ease;
}

.campaign-spread__count-suffix {
    font: var(--campaign-spread-introduction-font-style) var(--campaign-spread-introduction-font-variant) var(--campaign-spread-introduction-font-weight) 16px/var(--campaign-spread-introduction-line-height) var(--campaign-spread-introduction-font-stack);
}

.campaign-spread__wrapper {
    display: flex;
}


.campaign-spread-medium__image-container img {
    width: 100%;
}
