.swiper-contaner .flickity-viewport {
    overflow: visible;
}

.card-slider {
    margin-right: 15px;
    width: 100%;
}

@media(min-width: 530px) {
    .card-slider {
        width: 50%;
    }
}

@media(min-width: 800px) {
    .card-slider {
        width: 33%;
    }
}

@media(min-width: 1180px) {
    .card-slider {
        width: 25%;
    }
}
